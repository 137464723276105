/**
Perform a login with email and password.

email - string
password - string
isPartnerRequest - boolean - if true, this is a partner logging in. dont lock to brand.

Returns new state for the providers authentication key, if there's an error.
Returns null if theres no error.
*/
import Partner from "models/Partner";
import UserAccount from "models/UserAccount";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const doLogin = async (email, password, isPartnerRequest) => {
  // Determine info based on the current domain name.
  console.log("DoLogin", email, password);

  if (!password) {
    return {
      loading: false,
      error: true,
      errorMessage: "Password is required",
      errorCode: "auth/password-required"
    };
  }

  const partnerDetails = await Partner.getPartnerDetailsFromDomain();
  if (!partnerDetails) {
    return {
      loading: false,
      error: true,
      errorMessage: `Member brand not found (${window?.location?.hostname})`
    };
  }
  const { partner_id: partnerId, brand_id: brandId } = partnerDetails;

  // Login with the partner+brand email.
  try {
    await firebase.auth().signInWithEmailAndPassword(
      UserAccount.getAccountEmail({
        partner_id: partnerId,
        brand_id: isPartnerRequest ? null : brandId,
        email
      }),
      password
    );
  } catch (e) {
    const result = {
      loading: false,
      error: true,
      errorMessage: e.message,
      errorCode: e.code
    };

    if (e.code === "auth/wrong-password" && password === "default") {
      // Default password is wrong.
      return {
        loading: false,
        requiresPassword: true
      };
    }
    if (e.code === "auth/wrong-password") {
      // Password is not default and wrong.
      result.errorMessage = "Wrong password";
    } else if (e.code === "auth/user-not-found") {
      result.errorMessage = "Account does not exist";
    } else if (e.code === "auth/invalid-email") {
      result.errorMessage = "Invalid email format";
    } else if (e.code === "auth/too-many-requests") {
      result.errorMessage =
        "Account is disabled due to too many failed login attempts. Try again later.";
    }

    return result;
  }
  return null;
};

export default doLogin;
